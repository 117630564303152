import { Link, useNavigate } from "react-router-dom";
import styles from "./SellerRegister.module.scss";
// import {Loader} from "../Loader/Loader";
import { DefaultTable } from "../Table/Table";
import { useCallback, useEffect, useState } from "react";
import apiMethods from "../../../api/apiMethods";
import { Loader } from "../../Loader/Loader";

/* Список продавцов */

const headerData = {
  nameTT: "Название ТТ",
  statusTT: "Статус ТТ",
  name: "ФИО Продавца",
  email: "E-mail Продавца",
  sales: "Продажа, ед",
  count: "Кол-во литров",
  status: "Статус",
  action: "",
};

const editButton = (
  <button type="button">
    <img src="/icons/edit.png" alt="Редактировать" />
  </button>
);

const status = {
  works: {
    color: "#000",
  },
  fired: {
    color: '#000',
  },
  not_works: {
    color: "#CE263D",
  },
};

const statusTT = {
  active: {
    color: "#1AA00E",
  },
  in_process: {
    color: "#000",
  },
};

const prepareBodyData = (data) => {
  const result = data.reduce((acc, cur) => {
    acc.push({
      id: cur.id,
      nameTT: cur.point.name,
      statusTT: (
        <p style={{ color: statusTT[cur.point.status.slug].color }}>
          {cur.point.status.i18n}
        </p>
      ),
      name: cur.name,
      email: cur.email,
      sales: cur.total ? cur.total : "-",
      count: cur.liters ? cur.liters : "-",
      status: status[cur.status.slug] ? (
        <p style={{ color: status[cur.status.slug].color || '' }}>
          {cur.status.i18n}
        </p>
      ) : '-',
      action: editButton,
    });

    return acc;
  }, []);

  return result
}

export function SellerRegister() {
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const fetchGetSellers = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await apiMethods.owner.getSellers();

      setTableData(prepareBodyData(data.data));
      setLoading(false);
    } catch ({ response }) {
      setLoading(false);
    }
  }, []);

  const handleCellClick = (val) => {
    if (val && val.id) {
      navigate(`/account/seller-edit/${val.id}`)
    }
  }

  useEffect(() => {
    fetchGetSellers();
  }, []);

  return (
    <section className={styles.container}>
      <div className="wrapper">
        <div className={styles.header}>
          <h2>Продавцы</h2>
          <Link to="/account/seller-add" className="btn btnPrimary btnIcon">
            Зарегистрировать продавца
          </Link>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <DefaultTable
            headerData={headerData}
            bodyData={tableData}
            isTableLoading={loading}
            onCellClick={handleCellClick}
          />
        )}
      </div>
    </section>
  );
}
